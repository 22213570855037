import { useMemo } from 'preact/hooks';
import { createRef, createContext } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TextOption from '../Option/TextOption.component';
import ImageOption from '../Option/ImageOption.component';

export const OptionWrapperContext = createContext({});

/**
 * Component for a single question option
 *
 * @param {object} props question option props
 * @param {object} props.question question data
 * @param {object} props.option question option data
 * @param {object} props.optionCls css classes
 * @param {boolean} props.showingResults question is showing results
 * @param {object} props.transitionStyles transition styles
 * @param {string} props.transitionState transition state
 * @param {number} props.optionsVisibleDelay delay for visibility transition
 * @param {Function} props.onSelect callback when option has been selected by user
 * @param {number} props.optionImageWidth image width for ODIR
 * @param {number} props.hasOptionImages does the option have images
 * @returns {Function} <Option />
 */
function OptionWrapper({
    question,
    option,
    optionCls,
    showingResults = false,
    transitionStyles,
    transitionState,
    optionsVisibleDelay,
    optionImageWidth,
    hasOptionImages,
    onSelect
}) {
    const optionTextRef = createRef();

    const optionWrapperMemo = useMemo(
        () => ({
            question,
            option,
            optionCls,
            showingResults,
            transitionStyles,
            transitionState,
            optionsVisibleDelay,
            optionImageWidth,
            hasOptionImages,
            onSelect,
            optionTextRef
        }),
        [
            question,
            option,
            optionCls,
            showingResults,
            transitionStyles,
            transitionState,
            optionsVisibleDelay,
            optionImageWidth,
            hasOptionImages,
            onSelect,
            optionTextRef
        ]
    ); // value is cached by useMemo

    if (!option) {
        return '';
    }

    return (
        <OptionWrapperContext.Provider value={optionWrapperMemo}>
            <button
                className={classNames(optionCls)}
                title={option.text}
                onClick={() => onSelect(option)}
            >
                {!hasOptionImages ? <TextOption /> : <ImageOption />}
            </button>
        </OptionWrapperContext.Provider>
    );
}

OptionWrapper.propTypes = {
    question: PropTypes.object.isRequired,
    option: PropTypes.object.isRequired,
    optionCls: PropTypes.object,
    showingResults: PropTypes.bool,
    transitionStyles: PropTypes.object,
    transitionState: PropTypes.string,
    optionsVisibleDelay: PropTypes.number,
    onSelect: PropTypes.func,
    optionImageWidth: PropTypes.number.isRequired,
    hasOptionImages: PropTypes.bool
};

export default OptionWrapper;
