import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { checkIfCorrect } from '../../../helpers/utils';

import TickIcon from '../../../icons/TickIcon';

import styleSheet from './styles/results.module.scss';
import CrossIcon from '../../../icons/CrossIcon';

const styles = styleSheet.locals || {};

/**
 * Component to show the Answers to the questions in the quiz
 *
 * @param {object} props quiz option props
 * @param {object} props.quiz quiz option data
 * @param {object} props.submissionSummary submission response data
 * @returns {Function} <Answers />
 */
function Answers({ quiz, submissionSummary }) {
    const { t } = useTranslation();

    const questions = submissionSummary?.questions
        ? submissionSummary?.questions
        : quiz?.questions;

    return (
        <>
            {questions?.map((question, index) => {
                const questionNumber = index + 1;

                const isCorrect = checkIfCorrect(question);

                const multipleFanAnswers =
                    question?.selected_options?.length > 1;

                const multipleCorrectAnswers =
                    question?.correct_options?.length > 1;

                const lastOptionAnswer =
                    question?.selected_options[
                        question?.selected_options.length - 1
                    ];

                const lastCorrectAnswer =
                    question?.correct_options[
                        question?.correct_options.length - 1
                    ];

                return (
                    <div
                        key={`answer-${question.question_id}`}
                        className={styles.resultsAnswerQuestion}
                        data-testid={`answer-${question.question_id}`}
                    >
                        <div
                            className={styles.resultsAnswerQuestionNumber}
                        >{`${t('quiz.q')}${questionNumber}`}</div>
                        <div className={styles.resultsAnswerQuestionText}>
                            <span>{question?.text}</span>

                            <span className={styles.resultsAnswerIcon}>
                                {isCorrect ? <TickIcon /> : <CrossIcon />}
                            </span>
                        </div>

                        <div className={styles.resultsYourAnswer}>
                            <div className={styles.resultsYourAnswerText}>
                                {isCorrect ? <TickIcon /> : <CrossIcon />}

                                {multipleFanAnswers
                                    ? t('quiz.yourAnswers')
                                    : t('quiz.yourAnswer')}

                                {/* code for space as ' ' not rendering correctly */}
                                {'\u00A0'}
                            </div>

                            <div className={styles.resultsYourAnswerAnswers}>
                                {question?.selected_options?.map(
                                    (fanAnswer) => {
                                        const isLastOptionAnswer =
                                            fanAnswer === lastOptionAnswer;

                                        return (
                                            <div
                                                key={`fan-answer-${fanAnswer.option_id}`}
                                            >
                                                {fanAnswer?.text}
                                                {multipleFanAnswers &&
                                                !isLastOptionAnswer ? (
                                                    <span
                                                        className={
                                                            styles.resultsComma
                                                        }
                                                    >
                                                        {`,${'\u00A0'}`}
                                                    </span>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </div>

                        {!isCorrect && (
                            <div
                                className={styles.resultsCorrectAnswer}
                                data-testid="correct-answer"
                            >
                                <div
                                    className={styles.resultsCorrectAnswerText}
                                >
                                    <TickIcon />

                                    {multipleCorrectAnswers
                                        ? t('quiz.correctAnswers')
                                        : t('quiz.correctAnswer')}

                                    {'\u00A0'}
                                </div>

                                <div
                                    className={
                                        styles.resultsCorrectAnswerAnswers
                                    }
                                >
                                    {question?.correct_options?.map(
                                        (correctOption) => {
                                            const isLastCorrectAnswer =
                                                correctOption ===
                                                lastCorrectAnswer;

                                            return (
                                                <div
                                                    key={`correct-answer-${correctOption.option_id}`}
                                                >
                                                    {correctOption?.text}
                                                    {multipleCorrectAnswers &&
                                                    !isLastCorrectAnswer ? (
                                                        <span
                                                            className={
                                                                styles.resultsComma
                                                            }
                                                        >
                                                            {`,${'\u00A0'}`}
                                                        </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
        </>
    );
}

Answers.propTypes = {
    quiz: PropTypes.object.isRequired,
    submissionSummary: PropTypes.object
};

export default Answers;
