const ArrowDownIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="8"
        fill="none"
        {...props}
    >
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M10.38.718 6.5 4.598 2.62.718a.996.996 0 1 0-1.41 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59a.996.996 0 0 0 0-1.41c-.39-.38-1.03-.39-1.42 0Z"
            clipRule="evenodd"
            {...props}
        />
    </svg>
);

export default ArrowDownIcon;
