import PropTypes from 'prop-types';
import classNames from 'classnames';

import styleSheet from './loading.module.scss';
import LoadingIcon from '../../../icons/LoadingIcon';

const styles = styleSheet.locals || {};

const defaultSize = 16;

const Loading = function ({ size = defaultSize, className = '', ...props }) {
    const cls = classNames(styles.loadingComponent, className);
    return (
        <>
            <style>{styleSheet.toString()}</style>
            <span data-cy="Loading" className={cls} {...props}>
                <LoadingIcon width={size} height={size} />
            </span>
        </>
    );
};

Loading.propTypes = {
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string
};

export default Loading;
