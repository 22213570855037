import { useState } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import styleSheet from './styles/results.module.scss';

import ArrowUpIcon from '../../../icons/ArrowUpIcon';
import ArrowDownIcon from '../../../icons/ArrowDownIcon';

import Answers from './Answers.component';

const styles = styleSheet.locals || {};

/**
 * Component for the Answers Accordion
 *
 * @param {object} props quiz option props
 * @param {object} props.quiz quiz option data
 * @param {object} props.submissionSummary submission response data
 * @returns {Function} <AnswersAccordion />
 */
function AnswersAccordion({ quiz, submissionSummary }) {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div
            className={styles.resultsAccordionBlock}
            data-testid="answers-accordion"
        >
            <style>{styleSheet.toString()}</style>
            <div className={styles.resultsAccordianWrapper}>
                <button
                    onClick={toggleAccordion}
                    className={styles.resultsAccordian}
                >
                    {isOpen ? (
                        <div>
                            {t('quiz.hideAnswers')} <ArrowUpIcon />
                        </div>
                    ) : (
                        <div>
                            {t('quiz.showAnswers')} <ArrowDownIcon />
                        </div>
                    )}
                </button>
            </div>
            {isOpen && (
                <div className={styles.resultsAnswersWrapper}>
                    <Answers
                        quiz={quiz}
                        submissionSummary={submissionSummary}
                    />
                </div>
            )}
        </div>
    );
}

AnswersAccordion.propTypes = {
    quiz: PropTypes.object.isRequired,
    submissionSummary: PropTypes.object
};

export default AnswersAccordion;
