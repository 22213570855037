import PropTypes from 'prop-types';

import Countdown from '../Countdown/Countdown.component';
import styleSheet from '../styles/quiz.module.scss';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

/**
 * Component for an unopened/scheduled quiz
 *
 * @param {object} props quiz option props
 * @param {object} props.quiz quiz option data
 * @param {boolean} props.quizScheduled if quiz is scheduled
 * @param {Function} props.onCountdownEnd callback when counter reaches 0
 * @returns {Function} <quizScheduledScreen />
 */
function ScheduledScreen({ quiz, quizScheduled = false, onCountdownEnd }) {
    const { t } = useTranslation();

    return (
        <div className={styles.quizScheduledText}>
            {quizScheduled && quiz.opens_at ? (
                <Countdown
                    text={t('quiz.opensIn')}
                    datetime={quiz.opens_at}
                    onCountdownEnd={onCountdownEnd}
                />
            ) : (
                <span data-testid="quiz-not-open">{t('quiz.notOpen')}</span>
            )}
        </div>
    );
}

ScheduledScreen.propTypes = {
    quiz: PropTypes.object.isRequired,
    quizScheduled: PropTypes.bool,
    onCountdownEnd: PropTypes.func.isRequired
};

export default ScheduledScreen;
