import PropTypes from 'prop-types';

import styleSheet from '../styles/quiz.module.scss';

import { getImageUrl } from '../../../helpers/images';

const styles = styleSheet.locals || {};

/**
 * Component for the quiz hero
 *
 * @param {object} props quiz option props
 * @param {object} props.quiz quiz option data
 * @returns {Function} <QuizHero />
 */
function Hero({ quiz }) {
    /* eslint-disable no-magic-numbers */
    return (
        <div className={styles.quizHero}>
            <img
                src={getImageUrl(quiz.hero_image_url, 1784)}
                alt={quiz.hero_image_text || ''}
                className={styles.quizHeroImage}
                data-testid={'hero-img'}
            />
        </div>
    );
}

Hero.propTypes = {
    quiz: PropTypes.object.isRequired
};

export default Hero;
