const ArrowUpIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="8"
        fill="none"
        {...props}
    >
        <path
            fill="#fff"
            fillRule="evenodd"
            d="m5.289 1.138-4.59 4.59a.996.996 0 1 0 1.41 1.41l3.89-3.88 3.88 3.88a.996.996 0 1 0 1.41-1.41l-4.59-4.59a.996.996 0 0 0-1.41 0Z"
            clipRule="evenodd"
            {...props}
        />
    </svg>
);

export default ArrowUpIcon;
