import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Question from '../Question/Question.component';

/**
 * Component for managing the questions on a quiz
 *
 * @param {object} props quiz option props
 * @param {object} props.quiz quiz option data
 * @param {object} props.currentQuestionIndex current question index
 * @param {number} props.transitionDelay  transition delay
 * @param {string} props.transitionState  transition state
 * @param {boolean} props.isPreview  is it the preview
 * @returns {Function} <quizSponsor />
 */
function Questions({
    quiz,
    currentQuestionIndex,
    transitionDelay,
    transitionState,
    isPreview
}) {
    const { t } = useTranslation();

    const question = isPreview
        ? quiz?.questions[currentQuestionIndex] || quiz?.questions[0]
        : quiz?.questions[currentQuestionIndex];

    if (!question) {
        return <div>{t('quiz.previewWait')}</div>;
    }

    /* eslint-disable no-magic-numbers */
    return (
        <Question
            question={question}
            transitionDelay={transitionDelay}
            transitionState={transitionState}
            isPreview={isPreview}
        />
    );
}

Questions.propTypes = {
    quiz: PropTypes.object.isRequired,
    currentQuestionIndex: PropTypes.number.isRequired,
    transitionDelay: PropTypes.number.isRequired,
    transitionState: PropTypes.string.isRequired,
    isPreview: PropTypes.bool
};

export default Questions;
