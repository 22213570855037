import PropTypes from 'prop-types';
import classNames from 'classnames';

import styleSheet from './styles/question.module.scss';

const styles = styleSheet.locals || {};

/**
 * Component for the question header
 *
 * @param {object} props question option props
 * @param {object} props.question question option data
 * @returns {Function} <Header />
 */
function QuestionHeader({ question }) {
    return (
        <div className={classNames(styles.questionHeader)}>
            <div className={classNames(styles.questionPrompt)}>
                <span
                    className={classNames(styles.questionQuestion)}
                    data-fek-preview="question-text"
                    data-testid={'question-title'}
                >
                    {question?.text || 'Question title'}
                </span>
                <div
                    className={classNames(styles.questionDescription)}
                    data-fek-preview="question-description"
                    data-testid={'question-description'}
                >
                    {question?.description || ''}
                </div>
            </div>
        </div>
    );
}

QuestionHeader.propTypes = {
    question: PropTypes.object.isRequired
};

export default QuestionHeader;
