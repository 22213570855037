import { render } from 'preact';

import { determineTenant } from '../helpers/client';
import { namespace } from '../helpers/constants';
import { hexToRGB } from '../helpers/colours';
import { I18nextProvider } from 'react-i18next';
import i18nInstance from '../i18n';
import { getQuizData } from './apiRequests';
import { getAuthHeader } from '../helpers/authentication';
import { DataCaptureForm as Form } from 'playbook-data-capture-sdk-web';

import Quiz from './quiz.component';

const ID_DATA_ATTRIBUTE = `data-${namespace}quiz`;
const ID_DATA_CAPTURE_FORM = `data-${namespace}form`;
const PREVIEW_DATA_ATTRIBUTE = `${ID_DATA_ATTRIBUTE}-preview`;

const INIT_ATTR = 'data-fek-initialised';
const TENANT_DATA_ATTRIBUTE = `data-${namespace}tenant`;
const NO_INIT_SELECTOR = `:not([${INIT_ATTR}])`;

/**
 * Class which goes through page and finds FEK quizzes to embed
 */
export default class Quizzes {
    constructor() {
        this.init();
        this.observeDOM();
    }

    init() {
        document
            .querySelectorAll(`[${ID_DATA_ATTRIBUTE}]${NO_INIT_SELECTOR}`)
            .forEach(this.initNewQuiz.bind(this));
    }

    initNewQuiz(quizEmbed) {
        const embedTenant = quizEmbed.getAttribute(TENANT_DATA_ATTRIBUTE);
        determineTenant(embedTenant);

        const quizId = quizEmbed.getAttribute(ID_DATA_ATTRIBUTE);

        quizEmbed.setAttribute(INIT_ATTR, '');

        // Fetch quiz --> getQuizData() method in apiRequests.js file
        getQuizData(quizId).then((quizData) => {
            if (!quizData) {
                return;
            }

            const authHeader = getAuthHeader();

            if (quizData?.gated && authHeader) {
                getQuizData(quizId, true, authHeader).then((gatedQuizData) => {
                    if (!gatedQuizData) {
                        return;
                    }

                    this.renderEmbed(quizEmbed, gatedQuizData, false);
                });
            } else {
                this.renderEmbed(quizEmbed, quizData, false);
            }
        });
    }

    initPreviews() {
        document
            .querySelectorAll(`[${PREVIEW_DATA_ATTRIBUTE}]`)
            .forEach((previewEmbed) => {
                const data = JSON.parse(
                    previewEmbed.getAttribute(PREVIEW_DATA_ATTRIBUTE)
                );
                if (data?.quiz_id) {
                    this.renderEmbed(previewEmbed, data, true);
                }
            });
    }

    /**
     * Sets up a MutationObserver to check the DOM
     * if new quiz embeds have been dynamically added
     */
    observeDOM() {
        const observer = new MutationObserver((mutationList) => {
            mutationList.forEach((mutation) => {
                mutation.addedNodes.forEach((node) => {
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        const quizEmbed = node.querySelector(
                            `[${ID_DATA_ATTRIBUTE}]${NO_INIT_SELECTOR}`
                        );
                        if (quizEmbed) {
                            this.initNewQuiz(quizEmbed);
                        }
                    }
                });
            });
        });

        observer.observe(document.body, { subtree: true, childList: true });
    }

    /**
     * Renders embeds to DOM
     *
     * @param {Element} container - embed container
     * @param {object} quizData - quiz data
     * @param {boolean} isPreview - quiz embed is a preview
     */
    async renderEmbed(container, quizData, isPreview) {
        const parentElement = container?.parentElement;

        // Check if there is a linked form
        const embeddedFormId =
            container.firstElementChild?.getAttribute(ID_DATA_CAPTURE_FORM);

        let embeddedFormData;
        if (embeddedFormId) {
            embeddedFormData = await Form.initEmbeddableNewForm(embeddedFormId)
                .then((formData) => formData)
                .catch((rejectValue) => rejectValue);
        }

        const refetch = (quizData, isGated, authHeader) => {
            return getQuizData(quizData?.quiz_id, isGated, authHeader);
        };

        if (parentElement) {
            if (parentElement.hasAttribute('data-fek-quiz')) {
                return;
            }
        }

        // Set BG colour and gradient overrides.
        const hexBgColour = quizData?.background_colour;
        const rgbBgColour = hexBgColour ? hexToRGB(hexBgColour) : null;

        const colourOverrides = {
            hex: hexBgColour,
            rgb: rgbBgColour
        };

        const shadow =
            container.shadowRoot || container.attachShadow({ mode: 'open' });
        render(
            <I18nextProvider i18n={i18nInstance}>
                <Quiz
                    quizData={quizData}
                    embeddedFormData={embeddedFormData}
                    container={container}
                    colourOverrides={colourOverrides}
                    refetch={refetch}
                    isPreview={isPreview}
                />
            </I18nextProvider>,
            shadow
        );
    }
}
