import Quizzes from './quiz';

/**
 * Initialisation of the SDK
 *
 */
function init() {
    window.PlaybookQuiz = new Quizzes();

    window.dispatchEvent(new Event('PLAYBOOK_QUIZZES_SDK_INIT'));
}

// Expose the init function on the window object
window.initPlaybookQuiz = function () {
    window.PlaybookQuiz = new Quizzes();
    window.dispatchEvent(new Event('PLAYBOOK_QUIZZES_SDK_INIT'));
};

// These sites are SPA's so need to use init func above
const initOnDocument =
    !window.location.host.includes('atpmedia.tv') &&
    !window.location.host.includes('tennistv');

if (initOnDocument) {
    switch (document.readyState) {
        case 'interactive':
        case 'complete':
            init();
            break;
        default: {
            document.addEventListener('DOMContentLoaded', init);
        }
    }
} else {
    window.dispatchEvent(new Event('PLAYBOOK_QUIZ_READY'));
}
