import PropTypes from 'prop-types';

import styleSheet from '../styles/quiz.module.scss';

import { getImageUrl } from '../../../helpers/images';

const styles = styleSheet.locals || {};

/**
 * Component for the quiz sponsor
 *
 * @param {object} props quiz option props
 * @param {object} props.quiz quiz option data
 * @returns {Function} <quizSponsor />
 */
function Sponsor({ quiz }) {
    /* eslint-disable no-magic-numbers */
    const sponsorUrl = quiz?.sponsor_url;

    const isValidUrl = (url) => {
        try {
            new URL(url);
            return true;
            /* eslint-disable-next-line id-length */
        } catch (_) {
            return false;
        }
    };

    const validUrl = isValidUrl(sponsorUrl);

    return (
        <div className={styles.quizSponsor}>
            {quiz.sponsor_label ? (
                <div
                    className={styles.quizSponsorLabel}
                    data-testid={'sponsor-label'}
                >
                    {quiz.sponsor_label}
                </div>
            ) : (
                ''
            )}

            {validUrl ? (
                <a href={sponsorUrl} target="_blank" rel="noopener noreferrer">
                    <img
                        src={getImageUrl(quiz.sponsor_image_url, 160)}
                        alt={quiz.sponsor_image_text || ''}
                        className={styles.quizSponsorImage}
                        data-testid={'sponsor-img'}
                    />
                </a>
            ) : (
                <img
                    src={getImageUrl(quiz.sponsor_image_url, 160)}
                    alt={quiz.sponsor_image_text || ''}
                    className={styles.quizSponsorImage}
                    data-testid={'sponsor-img'}
                />
            )}
        </div>
    );
}

Sponsor.propTypes = {
    quiz: PropTypes.object.isRequired
};

export default Sponsor;
