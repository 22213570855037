import { useCallback, useEffect, useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styleSheet from './styles/countdown.module.scss';

const styles = styleSheet.locals || {};

const SECOND_IN_MS = 1000;
let intervalRef = null;

/**
 * Countdown component
 *
 * @param {object} props props
 * @param {string} props.text text to display above counter
 * @param {string} props.datetime datetime when when countdown should reach 0
 * @param {Function} props.onCountdownEnd callback when counter reaches 0
 * @param {boolean} props.closes countdown is for when the question is closing
 * @param {boolean} props.leftAligned countdown should be left aligned
 * @param {boolean} props.lightTheme render with light theme colours
 * @returns {Function} <Countdown />
 */
function Countdown({
    text = '',
    datetime,
    onCountdownEnd,
    closes = false,
    leftAligned = false,
    lightTheme = false
}) {
    const { t } = useTranslation();
    const endDate = new Date(datetime);
    const endDateStamp = endDate.getTime();
    const [countdown, setCountdown] = useState({});

    /* eslint-disable-next-line no-magic-numbers */
    const padNumber = (number) => (number < 10 ? `0${number}` : number);

    const updateCountdown = useCallback(() => {
        /* eslint-disable no-magic-numbers */
        const dateNowStamp = new Date().getTime();
        let timeDiff = Math.round((endDateStamp - dateNowStamp) / 1000);

        const days = Math.floor(timeDiff / (24 * 60 * 60));
        timeDiff = timeDiff - days * 24 * 60 * 60;

        const hours = Math.floor(timeDiff / (60 * 60));
        timeDiff = timeDiff - hours * 60 * 60;

        const minutes = Math.floor(timeDiff / 60);
        timeDiff = timeDiff - minutes * 60;

        const seconds = timeDiff;

        setCountdown({
            days: padNumber(days),
            hours: padNumber(hours),
            minutes: padNumber(minutes),
            seconds: padNumber(seconds)
        });

        if (
            days < 0 ||
            (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0)
        ) {
            clearInterval(intervalRef);
            onCountdownEnd();
        }
    }, [endDateStamp, onCountdownEnd]);

    const cls = classNames(styles.countdown, {
        [styles.countdownHidden]: typeof countdown.days === 'undefined',
        [styles.countdownClosing]: closes,
        [styles.countdownLeftAligned]: leftAligned,
        [styles.countdownLight]: lightTheme
    });

    useEffect(() => {
        if (endDate.getTime() - new Date().getTime() > 0) {
            intervalRef = setInterval(() => {
                updateCountdown();
            }, SECOND_IN_MS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateCountdown]);

    if (endDate.getTime() - new Date().getTime() <= 0) {
        return '';
    }

    return (
        <>
            <style>{styleSheet.toString()}</style>
            <div className={cls} data-testid="open-quiz-countdown">
                <div className={styles.countdownText}>{text}</div>
                <div className={styles.countdownContainer}>
                    <div className={styles.countdownItem}>
                        <div className={styles.countdownNumber}>
                            {countdown.days}
                        </div>
                        <div className={styles.countdownLabel}>
                            {t('common.day', { count: Number(countdown.days) })}
                        </div>
                    </div>
                    <div className={styles.countdownSeparator}>:</div>
                    <div className={styles.countdownItem}>
                        <div className={styles.countdownNumber}>
                            {countdown.hours}
                        </div>
                        <div className={styles.countdownLabel}>
                            {t('common.hour', {
                                count: Number(countdown.hours)
                            })}
                        </div>
                    </div>
                    <div className={styles.countdownSeparator}>:</div>
                    <div className={styles.countdownItem}>
                        <div className={styles.countdownNumber}>
                            {countdown.minutes}
                        </div>
                        <div className={styles.countdownLabel}>
                            {t('common.minute', {
                                count: Number(countdown.minutes)
                            })}
                        </div>
                    </div>
                    <div className={styles.countdownSeparator}>:</div>
                    <div className={styles.countdownItem}>
                        <div className={styles.countdownNumber}>
                            {countdown.seconds}
                        </div>
                        <div className={styles.countdownLabel}>
                            {t('common.second', {
                                count: Number(countdown.seconds)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

Countdown.propTypes = {
    text: PropTypes.string,
    datetime: PropTypes.string.isRequired,
    onCountdownEnd: PropTypes.func.isRequired,
    closes: PropTypes.bool,
    leftAligned: PropTypes.bool,
    lightTheme: PropTypes.bool
};

export default Countdown;
