const CrossIcon = (props) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.334732 0.334381C0.781053 -0.111928 1.50467 -0.111928 1.95099 0.334381L8.00001 6.38343L14.0491 0.334381C14.4953 -0.111928 15.219 -0.111928 15.6653 0.334381C16.1116 0.780702 16.1116 1.50431 15.6653 1.95064L9.61625 7.99966L15.6653 14.0487C16.1116 14.495 16.1116 15.2186 15.6653 15.6649C15.219 16.1112 14.4953 16.1112 14.0491 15.6649L8.00001 9.6159L1.95099 15.6649C1.50467 16.1112 0.781053 16.1112 0.334732 15.6649C-0.111577 15.2186 -0.111577 14.495 0.334732 14.0487L6.38378 7.99966L0.334732 1.95064C-0.111577 1.50431 -0.111577 0.780702 0.334732 0.334381Z"
        />
    </svg>
);

export default CrossIcon;
