import { useContext } from 'preact/hooks';
import classNames from 'classnames';

import { OptionWrapperContext } from './OptionWrapper.component';
import { QuizContext } from '../../quiz.component';

import TickIcon from '../../../icons/TickIcon';
import CrossIcon from '../../../icons/CrossIcon';

import { getLetterForOption } from '../../../helpers/utils';

import styleSheet from '../../components/Question/styles/question.module.scss';

const styles = styleSheet.locals || {};

/**
 * Component for a single question option
 *
 * @returns {Function} <TextOption />
 */
function TextOption() {
    const { isPreview } = useContext(QuizContext);

    const {
        question,
        option,
        showingResults = false,
        transitionStyles,
        transitionState,
        optionsVisibleDelay,
        optionTextRef,
        showCrossIcon = false
    } = useContext(OptionWrapperContext);

    const barTransitionStyles = {
        entered: { maxWidth: '100%' }
    };

    let letter;

    question?.options.forEach((optionItem, index) => {
        if (optionItem?.text === option?.text) {
            letter = getLetterForOption(index);
        }
    });

    if (isPreview && option?.overrideLetter) {
        letter = option.overrideLetter;
    }

    const isCorrectOption = question?.correct_options?.some(
        (correctOption) => correctOption.option_id === option.option_id
    );

    return (
        <div
            className={classNames(styles.questionOptionText, {
                [styles.questionOptionTextLeftJustify]: !showingResults,
                [styles.questionOptionBorder]: showCrossIcon
            })}
        >
            {showingResults && (
                <div
                    className={styles.questionOptionBar}
                    style={{
                        width: `${option.percentage}%`,
                        ...barTransitionStyles[transitionState],
                        transitionDelay: `${optionsVisibleDelay}ms`
                    }}
                />
            )}
            {!showingResults && !showCrossIcon && (
                <div className={styles.questionTriviaLetter}>{letter}</div>
            )}
            <span
                className={classNames(
                    styles.questionOptionLabel,
                    styles.questionOptionLabelTrivia
                )}
                ref={optionTextRef}
            >
                {showingResults && isCorrectOption && (
                    <span className={styles.questionIconWrap}>
                        <TickIcon />
                    </span>
                )}

                {(showingResults && !isCorrectOption) || showCrossIcon ? (
                    <span className={styles.questionIconWrap}>
                        <CrossIcon />
                    </span>
                ) : null}

                {option.text}
            </span>
            {showingResults && (
                <div className={styles.questionOptionRight}>
                    <div
                        className={styles.questionOptionData}
                        style={{
                            ...transitionStyles[transitionState],
                            transitionDelay: `${optionsVisibleDelay}ms`
                        }}
                    >
                        <div className={styles.questionOptionPercentage}>
                            {option.percentage}%
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default TextOption;
