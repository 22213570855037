import { useState, useEffect, useMemo, useCallback } from 'preact/hooks';
import { createContext } from 'preact';

import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';

import { isScheduled, isOpen, isClosed } from '../helpers/schedule';
import { STATES } from '../helpers/quiz-status';
import { getAuthHeader, checkForSignIn } from '../helpers/authentication';
import { sendAnswer, sendView } from './apiRequests';

import styleSheet from './components/styles/quiz.module.scss';

import Hero from './components/Hero/Hero.component';
import Header from './components/Header/Header.component';
import Sponsor from './components/Sponsor/Sponsor.component';
import Progress from './components/Progress/Progress.component';
import ScheduledScreen from './components/ScheduledScreen/ScheduledScreen.component';
import Results from './components/Results/Results.component';
import ShareUrlBox from './components/Share/ShareUrlBox.component';
import { DataCaptureFormComponent as Form } from 'playbook-data-capture-sdk-web';

import SubmitButton from './components/SubmitButton/SubmitButton.component';

import Questions from './components/Questions/Questions.component';
import Countdown from './components/Countdown/Countdown.component';

const styles = styleSheet.locals || {};
export const QuizContext = createContext({});
const THREE_SECONDS = 3000;

/**
 * Finds the index of the first unanswered question in a quiz.
 *
 * @param {Array<object>} questions - Array of question objects.
 * @returns {number} Index of the first unanswered question, or -1 if all questions are answered.
 */
function findNextQuestion(questions) {
    return questions.findIndex(
        (question) => question.selected_options.length === 0
    );
}

/**
 * Checks if the last question in the quiz contains any selected options.
 *
 * @param {object} quiz - The quiz object containing questions.
 * @param {Array} quiz.questions - An array of question objects.
 * @param {Array} quiz.questions[].selected_options - An array of selected options for a question.
 * @returns {boolean} - Returns `true` if the last question has selected options, otherwise `false`.
 */
function checkLastQuestionForOptions(quiz) {
    // Ensure the quiz has questions
    if (quiz.questions && quiz.questions.length > 0) {
        const lastQuestion = quiz.questions[quiz.questions.length - 1];
        // Check if the last question has selected options

        return (
            lastQuestion.selected_options &&
            lastQuestion.selected_options.length > 0
        );
    }

    return false; // Default to false if there are no questions
}

/**
 * Component for showing a quiz
 *
 * @param {object} props quiz props
 * @param {object} props.quizData quizing data
 * @param {object} props.embeddedFormData embedded form data
 * @param {object} props.container quiz embed container
 * @param {object} props.colourOverrides colour overrides
 * @param {Function} props.refetch function to refetch quiz data
 * @param {boolean} props.isPreview poll embed is a preview
 * @returns {Function} <Quiz />
 */
function Quiz({
    quizData,
    embeddedFormData,
    container,
    colourOverrides,
    refetch,
    isPreview = false
}) {
    const { t } = useTranslation();
    const [quiz, setQuiz] = useState(quizData);

    const [isSignedIn, setIsSignedIn] = useState(false);

    const isGated = quizData?.gated;
    const authHeader = getAuthHeader();

    const [currentQuestion, setCurrentQuestion] = useState(
        findNextQuestion(quiz?.questions) + 1
    );
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(
        findNextQuestion(quiz?.questions)
    );

    const [startEmbedAnimation, setStartEmbedAnimation] = useState(false);
    const [startBarsAnimation, setStartBarsAnimation] = useState(false);

    const [selectedOptions, setSelectedOptions] = useState([]);

    const [quizCompleted, setQuizCompleted] = useState(
        checkLastQuestionForOptions(quiz)
    );

    const [confettiPlaying, setConfettiPlaying] = useState(false);

    const [dataCaptureFormSubmitted, setDataCaptureFormSubmitted] =
        useState(false);

    const [goToQuizResult, setGoToQuizResult] = useState(false);

    const [submissionSummary, setSubmissionSummary] = useState(
        quiz?.submission_summary
    );

    useEffect(() => {
        setQuiz(quizData);
    }, [quizData]);

    useEffect(() => {
        applyQuizSettings();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setIsSignedIn(checkForSignIn());
    }, []);

    useEffect(() => {
        if (isPreview && quiz.previewVoted) {
            setQuizCompleted(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPreview, quiz.previewVoted]);

    const { ref } = useInView({
        triggerOnce: true,
        threshold: 0.2,
        onChange: (inView) => {
            if (inView) {
                setStartEmbedAnimation(true);
                setStartBarsAnimation(true);

                if (!isPreview && !quiz?.isMock) {
                    // Dont register a view when user cannot use the poll
                    if (isGated && !authHeader) {
                        return;
                    }

                    sendView(quiz?.quiz_id, isGated, authHeader).catch(
                        (error) => {
                            // Handle the error here
                            console.error('Error sending view:', error);
                        }
                    );
                }
            }
        }
    });

    const transitionStyles = {
        entered: { opacity: 1, transform: 'rotate(0deg)' }
    };

    const applyQuizSettings = () => {
        const quizSettingsAttr =
            container.previousElementSibling?.getAttribute('data-fek-settings');
        if (quizSettingsAttr) {
            const settings = JSON.parse(quizSettingsAttr);

            if (settings.primaryColour) {
                container.style.setProperty(
                    '--fek-primary',
                    settings.primaryColour
                );
            }

            if (settings.primaryColourRgb) {
                container.style.setProperty(
                    '--fek-primary-rgb',
                    settings.primaryColourRgb
                );
            }
        } else if (colourOverrides?.hex && colourOverrides?.rgb) {
            container?.style?.setProperty('--fek-primary', colourOverrides.hex);

            container?.style?.setProperty(
                '--fek-primary-rgb',
                colourOverrides.rgb
            );
        }
    };

    const onCountdownEnd = useCallback(() => {
        setTimeout(async () => {
            const updatedQuiz = await refetch(quizData, isGated, authHeader);
            setQuiz(updatedQuiz);
        }, THREE_SECONDS);
    }, [refetch, setQuiz]);

    const onVoteSubmit = useCallback(
        async (question, isLastQuestion) => {
            if (isPreview) {
                return;
            }

            if (selectedOptions.length > 0) {
                const answerPayload = {
                    question_id: question?.question_id,
                    answer_options: selectedOptions.map((option) => ({
                        option_id: option.option_id ?? null
                    }))
                };

                // Sends API requests
                sendAnswer(quiz.quiz_id, answerPayload, isGated, authHeader)
                    .then((response) =>
                        onSubmitSuccess(response, isLastQuestion)
                    )
                    .catch((error) => {
                        console.error(error.detail ?? error.message);
                    });
            } else {
                console.warn('NO OPTION SELECTED!');
            }
        },
        [quiz.quiz_id, selectedOptions, isPreview]
    );

    // move the quiz on by one
    const onSubmitSuccess = (response, isLastQuestion) => {
        // store the submission summary and question answers to instantly render the results
        // view
        setSubmissionSummary({
            ...response?.submission_summary,
            questions: response?.questions
        });

        if (isLastQuestion) {
            setQuizCompleted(true);

            setConfettiPlaying(true);
        }

        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setCurrentQuestion(currentQuestion + 1);
        setSelectedOptions([]);
    };

    // Submit btn logic
    const onAnswer = useCallback(
        (question, isLastQuestion) => {
            onVoteSubmit(question, isLastQuestion);
        },
        [onVoteSubmit]
    );

    const quizScheduled = isScheduled(quiz);
    const quizOpen = isOpen(quiz);
    const quizClosed = isClosed(quiz);

    const transitionDelay = 100;

    const quizMemo = useMemo(
        () => ({
            quiz,
            currentQuestion,
            setCurrentQuestion,
            startBarsAnimation,
            setCurrentQuestionIndex,
            startEmbedAnimation,
            quizOpen,
            transitionStyles,
            onCountdownEnd,
            onAnswer,
            selectedOptions,
            setSelectedOptions,
            onVoteSubmit,
            isPreview,
            isSignedIn,
            setIsSignedIn,
            isGated
        }),
        [
            quiz,
            startBarsAnimation,
            currentQuestion,
            setCurrentQuestion,
            setCurrentQuestionIndex,
            startEmbedAnimation,
            quizOpen,
            transitionStyles,
            onCountdownEnd,
            onAnswer,
            selectedOptions,
            setSelectedOptions,
            onVoteSubmit,
            isPreview,
            isSignedIn,
            setIsSignedIn,
            isGated
        ]
    ); // value is cached by useMemo

    // TODO: Maybe combine
    let showProgress = quiz?.state === STATES.OPEN && !quizCompleted;
    let showQuestions = quiz?.state === STATES.OPEN && !quizCompleted;
    const showShareUrlBox = quiz?.state === STATES.OPEN && quizCompleted;

    let showDataCaptureForm =
        embeddedFormData?.state === 'open' && quizCompleted;

    let showResults = submissionSummary?.total_questions;

    if (goToQuizResult) {
        showResults = quizCompleted;
        showDataCaptureForm = false;
    }

    // The below overrides are used to control the preview behavior in the admin view
    // they will not apply to the fan view.
    if (quiz?.overrideShowResults === true) {
        showResults = true;
        showQuestions = false;
    }

    if (quiz?.overrideShowQuestions === true) {
        showQuestions = true;
        showProgress = true;
        showResults = false;
    }

    if (quiz?.hideProgress) {
        showProgress = false;
    }

    const showScheduledScreen = !quizOpen && quizScheduled;

    const showClosedIncomplete = quizClosed && !quiz?.submission_summary;

    return (
        <QuizContext.Provider value={quizMemo}>
            <style>{styleSheet.toString()}</style>
            <Transition nodeRef={ref} in={startEmbedAnimation}>
                {(transitionState) => (
                    <div
                        ref={ref}
                        className={classNames(styles.quiz, {
                            [styles.quizScheduled]: quizScheduled
                        })}
                        style={{
                            ...transitionStyles[transitionState]
                        }}
                    >
                        {quiz.hero_image_url && <Hero quiz={quiz} />}

                        <div className={styles.quizContent}>
                            <Header
                                quiz={quiz}
                                quizScheduled={quizScheduled}
                                quizClosedAndIncomplete={showClosedIncomplete}
                            />

                            {showScheduledScreen && (
                                <ScheduledScreen
                                    quiz={quiz}
                                    quizScheduled={quizScheduled}
                                    onCountdownEnd={onCountdownEnd}
                                />
                            )}

                            {showProgress && (
                                <Progress
                                    questions={quiz.questions}
                                    isPreview={isPreview}
                                />
                            )}

                            {showQuestions && (
                                <Questions
                                    quiz={quiz}
                                    currentQuestionIndex={currentQuestionIndex}
                                    transitionDelay={transitionDelay}
                                    transitionState={transitionState}
                                    isPreview={isPreview}
                                />
                            )}

                            {showDataCaptureForm && (
                                <>
                                    <Form
                                        formData={embeddedFormData}
                                        submittedCallback={() =>
                                            setDataCaptureFormSubmitted(true)
                                        }
                                        pollId={quiz.quiz_id}
                                        isQuiz={true}
                                    />

                                    {dataCaptureFormSubmitted && (
                                        <SubmitButton
                                            onSubmit={() =>
                                                setGoToQuizResult(true)
                                            }
                                            buttonText={t('button.revealScore')}
                                            title="View quiz results"
                                        />
                                    )}
                                </>
                            )}

                            {showResults && (
                                <Results
                                    quiz={quiz}
                                    confettiPlaying={confettiPlaying}
                                    setConfettiPlaying={setConfettiPlaying}
                                    submissionSummary={submissionSummary}
                                />
                            )}

                            {quiz?.closes_at && (
                                <Countdown
                                    text={t('quiz.closesIn')}
                                    datetime={quiz.closes_at}
                                    onCountdownEnd={onCountdownEnd}
                                    closes={true}
                                />
                            )}

                            {showShareUrlBox && (
                                <ShareUrlBox
                                    quizId={quiz?.quiz_id}
                                    style={{
                                        ...transitionStyles[transitionState],
                                        transitionDelay: `${transitionDelay}ms`
                                    }}
                                />
                            )}

                            {quizClosed && (
                                <div
                                    className={styles.quizClosed}
                                    data-testid="closed-text"
                                >
                                    {t('quiz.closed')}
                                </div>
                            )}

                            {quiz.sponsor_image_url && <Sponsor quiz={quiz} />}
                        </div>
                    </div>
                )}
            </Transition>
        </QuizContext.Provider>
    );
}

Quiz.propTypes = {
    quizData: PropTypes.object.isRequired,
    embeddedFormData: PropTypes.object,
    container: PropTypes.node.isRequired,
    colourOverrides: PropTypes.object,
    refetch: PropTypes.func.isRequired,
    isPreview: PropTypes.bool
};

export default Quiz;
