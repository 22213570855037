import { useContext } from 'preact/hooks';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { QuizContext } from '../../quiz.component';

import styleSheet from './styles/progress.module.scss';

const styles = styleSheet.locals || {};

/**
 * Progress Component
 *
 * @param {object} props quiz option props
 * @param {object} props.questions quiz option data
 * @param {object} props.isPreview is this in a preview
 * @returns {Function} <Progress />
 */
function Progress({ questions, isPreview }) {
    /* eslint-disable no-magic-numbers */

    const { currentQuestion } = useContext(QuizContext);

    return (
        <>
            <style>{styleSheet.toString()}</style>
            <div className={styles.progress} data-testid="progress">
                <div className={styles.progressWrapper}>
                    <div className={styles.progressBar}>
                        {questions?.map((question, index) => {
                            const questionNumber = index + 1;

                            const overrideActive = isPreview && index === 0;

                            return (
                                <span
                                    key={questionNumber}
                                    data-testid={`question-index-${questionNumber}`}
                                    className={classNames(
                                        styles.progressQuestion,
                                        {
                                            [styles.progressQuestionActive]:
                                                currentQuestion ===
                                                    questionNumber ||
                                                overrideActive,
                                            [styles.progressQuestionCompleted]:
                                                currentQuestion > questionNumber
                                        }
                                    )}
                                >
                                    {questionNumber}
                                </span>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

Progress.propTypes = {
    questions: PropTypes.object.isRequired,
    isPreview: PropTypes.bool
};

export default Progress;
