import PropTypes from 'prop-types';
import { useRef, useContext } from 'preact/hooks';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';

import OptionWrapper from '../Option/OptionWrapper.component';

import styleSheet from './styles/question.module.scss';

import { questionHasOptionImages } from './question-helpers';

import { QuizContext } from '../../quiz.component';

const styles = styleSheet.locals || {};

/**
 * Component for showing the question options
 *
 * @param {object} props quiz option props
 * @param {object} props.question question data
 * @param {object} props.selectedOptions options the fan has selected for the question
 * @param {object} props.setSelectedOptions setter func for selected options
 * @returns {Function} <OptionsList />
 */
function OptionsList({ question, selectedOptions, setSelectedOptions }) {
    const { quizOpen, startEmbedAnimation } = useContext(QuizContext);

    const hasOptionImages = questionHasOptionImages(question);
    const maxSelection = question?.correct_options.length;

    const nodeRef = useRef(null);

    /**
     * Handles the selection of an option in a quiz.
     * Toggles the option in the selected options list if the quiz is open.
     *
     * @param {object} option - The option to select or deselect.
     * @param {string} option.text - The text of the option.
     */
    const onSelect = (option) => {
        if (!quizOpen) {
            return;
        }

        const found = selectedOptions.findIndex(
            (opt) => opt.text === option.text
        );

        if (found === -1) {
            updateSelectedOptions(option);
        } else {
            const options = [...selectedOptions];
            options.splice(found, 1);
            setSelectedOptions(options);
        }
    };

    /**
     * Returns grid css class depending on how many options there are
     *
     * @param {number} numberOfOptions number of answer options
     * @returns {string} grid class
     */
    const getGridCls = (numberOfOptions) => {
        /* eslint-disable no-magic-numbers */
        if (numberOfOptions < 4) {
            return '';
        }

        if (numberOfOptions === 6 || numberOfOptions === 9) {
            return styles.questionOptionsGridThree;
        }

        if (numberOfOptions % 4 === 1 || numberOfOptions % 4 === 2) {
            return styles.questionOptionsGridFourThreeTwo;
        }

        return styles.questionOptionsGrid;
    };

    /**
     * Sets the selected option as selected.
     * If only max 1 option is allowed replece current selected
     * option with newly selected one.
     *
     * @param {object} option selected question option
     */
    const updateSelectedOptions = (option) => {
        if (selectedOptions.length >= maxSelection) {
            if (maxSelection === 1) {
                setSelectedOptions([option]);
            }
            return;
        }
        const options = [...selectedOptions, option];
        setSelectedOptions(options);
    };

    let optionsToRender;

    optionsToRender = question.options;

    const duration = 300;
    const transitionStyles = {
        entered: { opacity: 1, transform: 'translateY(0)' }
    };

    const transitionDelay = 100;
    const transitionLength = 300;

    const optionsVisibleDelay =
        optionsToRender.length * transitionDelay + transitionLength;
    const gridCls = hasOptionImages ? getGridCls(optionsToRender.length) : '';
    const containerCls = classNames(styles.questionOptions, gridCls, {
        [styles.questionOptionsImages]: hasOptionImages
    });

    // eslint-disable-next-line no-magic-numbers
    const optionImageWidth = optionsToRender.length > 3 ? 412 : 840;

    return (
        <ul className={containerCls}>
            {optionsToRender.map((option, index) => {
                const isSelected = selectedOptions?.find(
                    (opt) => opt.text === option.text
                );

                const delay = transitionDelay * (index + 1);

                const optionCls = classNames(styles.questionOption, {
                    [styles.questionOptionNoHover]: !quizOpen,
                    [styles.questionOptionSelected]: isSelected,
                    [styles.questionOptionDisabled]:
                        !isSelected && selectedOptions?.length === maxSelection,
                    [styles.questionOptionEnded]: !quizOpen,
                    [styles.questionOptionImage]: hasOptionImages
                });

                return (
                    <Transition
                        nodeRef={nodeRef}
                        timeout={duration}
                        in={startEmbedAnimation}
                        key={option.text}
                    >
                        {(state) => (
                            <li
                                className={classNames(
                                    styles.questionOptionItem
                                )}
                                ref={nodeRef}
                                style={{
                                    ...transitionStyles[state],
                                    transitionDelay: `${delay}ms`
                                }}
                                data-testid={`question-option-${index}`}
                            >
                                <OptionWrapper
                                    question={question}
                                    option={option}
                                    optionCls={optionCls}
                                    transitionStyles={transitionStyles}
                                    transitionState={state}
                                    optionsVisibleDelay={optionsVisibleDelay}
                                    onSelect={onSelect}
                                    optionImageWidth={optionImageWidth}
                                    hasOptionImages={hasOptionImages}
                                />
                            </li>
                        )}
                    </Transition>
                );
            })}
        </ul>
    );
}

OptionsList.propTypes = {
    question: PropTypes.object,
    selectedOptions: PropTypes.array,
    setSelectedOptions: PropTypes.func
};

export default OptionsList;
