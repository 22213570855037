/**
 * Converts an index into a corresponding letter (A, B, C, etc.).
 *
 * @param {number} index - The index to convert into a letter.
 * @returns {string} - The letter corresponding to the index.
 */
export const getLetterForOption = (index) => {
    const isArabic = document.documentElement?.lang?.startsWith('ar');
    // eslint-disable-next-line no-magic-numbers
    const firstAlphabetLetter = isArabic ? 0x0627 : 65;
    // ASCII code for 'A' is 65. Adding the index shifts the ASCII value to the next letter.
    return String.fromCodePoint(firstAlphabetLetter + index).toUpperCase();
};

/**
 * Determines if the user got 50% or more of the questions correct.
 *
 * @param {number} totalCorrect - The total number of correctly answered questions.
 * @param {number} totalQuestions - The total number of questions.
 * @returns {boolean} - Returns `true` if the user got 50% or more correct, otherwise `false`.
 */
export const isOverFiftyPercentCorrect = (totalCorrect, totalQuestions) => {
    if (totalQuestions === 0) {
        return false; // Prevent division by zero
    }
    /* eslint-disable no-magic-numbers */
    return totalCorrect / totalQuestions >= 0.5;
};

/**
 * Checks if the selected options match the correct options in a question object.
 *
 * @param {object} question - The question object containing selected and correct options.
 * @param {Array<object>} question.selected_options - Array of selected option objects.
 * @param {Array<object>} question.correct_options - Array of correct option objects.
 * @returns {boolean} - Returns true if the selected options match the correct options, otherwise false.
 */
export const checkIfCorrect = (question) => {
    const selectedIds = question.selected_options.map(
        (option) => option.option_id
    );
    const correctIds = question.correct_options.map(
        (option) => option.option_id
    );

    // Sort the IDs for consistent comparison
    const sortedSelected = [...selectedIds].sort((a, b) => a - b);
    const sortedCorrect = [...correctIds].sort((a, b) => a - b);

    // Compare the arrays
    if (sortedSelected.length !== sortedCorrect.length) {
        return false;
    }

    return sortedSelected.every((id, index) => id === sortedCorrect[index]);
};
