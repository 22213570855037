import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import classNames from 'classnames';

import styleSheet from './scss/share-button.module.scss';
import ShareIcon from '../../../icons/ShareIcon';
import TickIcon from '../../../icons/TickIcon';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};
const COPY_MESSAGE_TIMEOUT = 2000;

/**
 * Renders a shareable URL and copy button.
 *
 * @param {string} quizId - quiz ID
 * @returns {Function} <Share />
 */
const ShareButton = function ({ quizId }) {
    const { t } = useTranslation();

    const copyUrl = `${window.location.origin}${window.location.pathname}#fek-${quizId}`;
    const [hasCopied, setHasCopied] = useState(false);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(copyUrl).then(() => {
            setHasCopied(true);
            setTimeout(() => {
                setHasCopied(false);
            }, COPY_MESSAGE_TIMEOUT);
        });
    };

    const buttonCls = classNames(styles.shareButton, {
        [styles.shareButtonCopied]: hasCopied
    });

    return (
        <>
            <style>{styleSheet.toString()}</style>
            <div className={styles.share} data-testId="share-btn">
                <button className={buttonCls} onClick={handleCopyClick}>
                    {!hasCopied && <ShareIcon className={styles.shareIcon} />}
                    {hasCopied && <TickIcon className={styles.shareSuccess} />}
                </button>

                <div className={styles.shareCopied}>
                    {t('button.shareCopied')}
                </div>
            </div>
        </>
    );
};

ShareButton.propTypes = {
    quizId: PropTypes.string.isRequired
};

export default ShareButton;
