import { createRef } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ShareButton from '../Share/ShareButton.component';

import styleSheet from '../styles/quiz.module.scss';

const styles = styleSheet.locals || {};

/**
 * Component for the quiz header
 *
 * @param {object} props quiz option props
 * @param {object} props.quiz quiz option data
 * @param {boolean} props.quizScheduled is it scheduled
 * @param {boolean} props.quizClosedAndIncomplete is the quiz closed AND user has not completed it
 * @returns {Function} <Header />
 */
function Header({
    quiz,
    quizScheduled = false,
    quizClosedAndIncomplete = false
}) {
    const titleRef = createRef();
    const descrRef = createRef();

    return (
        <div
            className={classNames(styles.quizHeader, {
                [styles.quizHeaderScheduled]: quizScheduled,
                [styles.quizHeaderClosedIncomplete]: quizClosedAndIncomplete
            })}
        >
            <div
                className={classNames(styles.quizPrompt, {
                    [styles.quizPromptTrivia]: quizScheduled
                })}
            >
                <span
                    className={classNames(styles.quizQuestion, {
                        [styles.quizQuestionTrivia]: quizScheduled
                    })}
                    ref={titleRef}
                    data-fek-preview="text"
                    data-testid={'quiz-title'}
                >
                    {quiz.text}
                </span>
                <div
                    className={classNames(styles.quizDescription, {
                        [styles.quizDescriptionTrivia]: quizScheduled
                    })}
                    ref={descrRef}
                    data-fek-preview="description"
                    data-testid={'quiz-description'}
                >
                    {quiz.description}
                </div>
            </div>

            {!quizScheduled && !quizClosedAndIncomplete && (
                <ShareButton quizId={quiz.quiz_id} />
            )}
        </div>
    );
}

Header.propTypes = {
    quiz: PropTypes.object.isRequired,
    quizScheduled: PropTypes.bool,
    quizClosedAndIncomplete: PropTypes.bool
};

export default Header;
