const TrophyIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="19"
        fill="none"
        {...props}
    >
        <path
            fill="#fff"
            d="M4 18.428v-2h4v-3.1a5.29 5.29 0 0 1-2.19-1.04 4.53 4.53 0 0 1-1.41-1.91c-1.25-.15-2.3-.7-3.14-1.64A4.82 4.82 0 0 1 0 5.428v-1c0-.55.2-1.02.59-1.41.39-.39.86-.59 1.41-.59h2v-2h10v2h2c.55 0 1.02.2 1.41.59.39.39.59.86.59 1.41v1c0 1.27-.42 2.37-1.26 3.31-.84.94-1.89 1.49-3.14 1.64-.3.77-.77 1.4-1.41 1.91-.64.51-1.37.85-2.19 1.04v3.1h4v2H4Zm0-10.2v-3.8H2v1c0 .63.18 1.2.55 1.71.37.51.85.87 1.45 1.09Zm10 0c.6-.22 1.08-.58 1.45-1.09.37-.51.55-1.08.55-1.71v-1h-2v3.8Z"
        />
    </svg>
);

export default TrophyIcon;
