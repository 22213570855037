import { useContext } from 'preact/hooks';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import styleSheet from './styles/question.module.scss';

import { getTotalVotes } from '../../../helpers/schedule';

import { QuizContext } from '../../quiz.component';
import SignInGate from '../Gate/SignInGate.component';

import QuestionHeader from './QuestionHeader.component';
import OptionsList from './OptionsList.component';
import SubmitButton from '../SubmitButton/SubmitButton.component';
import TotalVotes from '../TotalVotes/TotalVotes.component';

const ONE_THOUSAND = 1000;

const styles = styleSheet.locals || {};

/**
 * Component for managing the questions on a quiz
 *
 * @param {object} props quiz option props
 * @param {object} props.question question data
 * @param {number} props.transitionDelay  transition delay
 * @param {string} props.transitionState  transition state
 * @returns {Function} <quizSponsor />
 */
function Question({ question, transitionDelay, transitionState }) {
    /* eslint-disable no-magic-numbers */
    const { t } = useTranslation();

    const {
        quiz,
        transitionStyles,
        onAnswer,
        selectedOptions,
        setSelectedOptions,
        isPreview,
        isSignedIn,
        isGated
    } = useContext(QuizContext);

    const totalVotes = isPreview ? 0 : getTotalVotes(question);
    const showVotesCounter = totalVotes > ONE_THOUSAND;

    const isArabic = document.documentElement.lang === 'ar';
    const numAnswers = question?.correct_options?.length;
    const remainingSelections = numAnswers - selectedOptions?.length;

    const isLastQuestion =
        quiz?.questions.indexOf(question) === quiz?.questions.length - 1;

    const isDisabled = () => {
        return selectedOptions?.length === 0 || remainingSelections !== 0;
    };

    return (
        <>
            <style>{styleSheet.toString()}</style>
            <div className={styles.questionWrapper}>
                <QuestionHeader question={question} />

                <div className={styles.questionWrapperGate}>
                    {!isSignedIn && isGated && !isPreview && <SignInGate />}

                    <OptionsList
                        question={question}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                    />

                    {numAnswers ? (
                        <div
                            className={`${styles.questionSelectAnswersLabel} ${
                                remainingSelections === 0
                                    ? styles.questionSlideUp
                                    : ''
                            }`}
                        >
                            {/* Workaround for incorrect RTL direction in a sentence with variables */}
                            {isArabic
                                ? `
                        ${t('common.remainingSelection_1')} ${t('quiz.option', {
                            count: remainingSelections
                        })} ${remainingSelections} ${t(
                            'common.remainingSelection_2'
                        )}
                    `
                                : t('common.remainingSelection', {
                                      number: remainingSelections,
                                      option: t('quiz.option', {
                                          count: remainingSelections
                                      })
                                  })}
                        </div>
                    ) : null}

                    <div
                        className={styles.questionSubmitContainer}
                        style={{
                            ...transitionStyles?.[transitionState],
                            transitionDelay: `${transitionDelay}ms`
                        }}
                    >
                        <SubmitButton
                            onSubmit={() => onAnswer(question, isLastQuestion)}
                            disabled={isDisabled()}
                            title={
                                isLastQuestion
                                    ? t('quiz.submitCompleteTooltip')
                                    : t('quiz.submitTooltip')
                            }
                            buttonText={
                                isLastQuestion
                                    ? t('quiz.submitCompleteQuiz')
                                    : t('quiz.submit')
                            }
                        />

                        {showVotesCounter && (
                            <TotalVotes
                                votes={totalVotes}
                                style={{
                                    ...transitionStyles?.[transitionState]
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

Question.propTypes = {
    question: PropTypes.object.isRequired,
    transitionDelay: PropTypes.number.isRequired,
    transitionState: PropTypes.string.isRequired
};

export default Question;
