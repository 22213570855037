import { useTranslation } from 'react-i18next';

import styleSheet from '../styles/quiz.module.scss';

import SignInIcon from '../../../icons/SignInIcon';
import RefreshIcon from '../../../icons/RefreshIcon';

const styles = styleSheet.locals || {};
/**
 * SignInGate component
 *
 * @returns {Function} <SignInGate />
 */
function SignInGate() {
    const { t } = useTranslation();

    const getTitleKey = () => {
        return 'signInGate.signInQuiz';
    };

    const handleRefreshClick = () => {
        window.location.reload();
    };

    const handleBtnClick = () => {
        if (window?.Playbook?.setSSORedirect) {
            window.Playbook.setSSORedirect();
        }

        if (window?.Playbook?.RegisterLink) {
            window.location.href = window.Playbook.RegisterLink;
        } else if (window?.Playbook?.SignInLink) {
            window.location.href = window.Playbook.SignInLink;
        } else {
            // fallback to the wta login page
            window.location.href = '/login';
        }
    };

    const isWTA = window?.location?.host?.includes('wta');
    const signInMessage = t(getTitleKey());

    return (
        <div className={styles.quizGate} data-testid="sign-in-gate">
            <div className={styles.quizGateText}>
                <div className={styles.quizGateIcon}>
                    <SignInIcon />
                </div>

                {isWTA ? (
                    <button
                        className={styles.quizGateBtn}
                        onClick={handleBtnClick}
                        title={signInMessage}
                        data-testid="wta-sign-in-btn"
                    >
                        {signInMessage}
                    </button>
                ) : (
                    <div
                        className={styles.quizGateTitle}
                        data-testid="sign-in-message"
                    >
                        {signInMessage}
                    </div>
                )}

                <div className={styles.quizGateSubtitle}>
                    {t('signInGate.alreadySignedIn')}
                    <button
                        onClick={handleRefreshClick}
                        onKeyDown={handleRefreshClick}
                        className={styles.quizGateRefreshWrapper}
                        data-testid={'sign-in-refresh'}
                    >
                        <span className={styles.quizGateRefresh}>
                            {t('signInGate.refreshBrowser')}
                        </span>
                        <span className={styles.quizGateRefreshIcon}>
                            <RefreshIcon />
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
}
export default SignInGate;
