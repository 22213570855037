import { STATES } from './quiz-status';

/**
 * Checks if given quiz is open for quizzing yet
 *
 * @param {object} quiz quiz data
 * @returns {boolean} quiz is open
 */
export const isOpen = (quiz) => {
    return quiz.state === 'open';
};

/**
 * Checks if given quiz is closed
 *
 * @param {object} quiz quiz data
 * @returns {boolean} quiz is closed
 */
export const isClosed = (quiz) => {
    return quiz.state === 'closed';
};

/**
 * Check is given quiz is not open for quizzing yet
 *
 * @param {object} quiz quiz data
 * @returns {boolean} quiz is scheduled
 */
export const isScheduled = (quiz) => {
    const now = new Date();
    const openDate = new Date(quiz.opens_at);
    return quiz.state === STATES.DRAFT || openDate > now;
};

/**
 * Counts the votes for each option to get the total.
 *
 * @param {object} question - question data
 * @returns {number} total amount of votes
 */
export const getTotalVotes = (question) => {
    return (
        question?.options?.reduce((prev, next) => {
            return prev + next.times_selected;
        }, 0) || 0
    );
};
