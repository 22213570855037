/**
 * Checks if the question options are images
 *
 * @param {object} question question data
 * @returns {boolean} has images as option
 */
export const questionHasOptionImages = (question) => {
    const options = question?.options;
    if (!options) {
        return false;
    }
    return (
        options.filter((opt) => opt.image_url || opt.image_data_preview)
            .length > 0
    );
};
