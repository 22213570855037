import { useContext } from 'preact/hooks';

import { OptionWrapperContext } from './OptionWrapper.component';

import { getImageUrl } from '../../../helpers/images';
import { getLetterForOption } from '../../../helpers/utils';

import TickIcon from '../../../icons/TickIcon';
import CrossIcon from '../../../icons/CrossIcon';

import styleSheet from '../../components/Question/styles/question.module.scss';

const styles = styleSheet.locals || {};

/**
 * Component for a single question option
 *
 * @returns {Function} <ImageOption />
 */
function ImageOption() {
    const {
        question,
        option,
        showingResults = false,
        transitionStyles,
        transitionState,
        optionsVisibleDelay,
        optionTextRef,
        optionImageWidth
    } = useContext(OptionWrapperContext);

    const barTransitionStyles = {
        entered: { maxWidth: '100%' }
    };

    let letter;

    question?.options.forEach((optionItem, index) => {
        if (optionItem?.text === option?.text) {
            letter = getLetterForOption(index);
        }
    });

    const isCorrectOption = question?.correct_options?.some(
        (correctOption) => correctOption.option_id === option.option_id
    );

    /* eslint-disable no-magic-numbers */
    return (
        <>
            {(option.image_url || option.image_data_preview) && (
                <div className={styles.questionOptionImg}>
                    <img
                        src={getImageUrl(option.image_url, optionImageWidth)}
                        alt="hero-img"
                    />
                </div>
            )}

            <div className={styles.questionOptionText}>
                {showingResults && (
                    <div
                        className={styles.questionOptionBar}
                        style={{
                            width: `${option.percentage}%`,
                            ...barTransitionStyles[transitionState],
                            transitionDelay: `${optionsVisibleDelay}ms`
                        }}
                    />
                )}

                <div className={styles.questionAnswerWrapper}>
                    {showingResults && isCorrectOption && (
                        <span className={styles.questionIconWrap}>
                            <TickIcon />
                        </span>
                    )}

                    {showingResults && !isCorrectOption && (
                        <span className={styles.questionIconWrap}>
                            <CrossIcon />
                        </span>
                    )}

                    <div>
                        <span
                            className={styles.questionOptionLabel}
                            ref={optionTextRef}
                        >
                            {!showingResults && (
                                <div className={styles.questionTriviaLetter}>
                                    {letter}
                                </div>
                            )}

                            {option.text}
                        </span>

                        {showingResults && (
                            <div
                                className={styles.questionOptionData}
                                style={{
                                    ...transitionStyles[transitionState],
                                    transitionDelay: `${optionsVisibleDelay}ms`
                                }}
                            >
                                <div
                                    className={styles.questionOptionPercentage}
                                >
                                    {option.percentage}%
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ImageOption;
