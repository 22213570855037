import { getClientHost } from '../helpers/client';
import { getOrCreateEncodedUUID } from '../helpers/user-meta';

/**
 * Sort quiz questions by view_order property as they are not
 * always returned in correct order
 *
 * @param {object} quiz - quiz data
 */
export const sortQuizOptions = (quiz) => {
    quiz.questions?.sort((a, b) => a.view_order - b.view_order);
};

/**
 * Fetches quiz data from API
 *
 * @param {string} quizId - FEK quiz ID
 * @param {boolean} isGated - is the quiz gated
 * @param {string} authHeader - is the user signed in to SSO
 * @returns {Promise} -
 */
export const getQuizData = (quizId, isGated, authHeader) => {
    const endpoint = isGated
        ? `https://${getClientHost()}${
              process.env.API_PATH
          }/gated/quizzes/${quizId}`
        : `https://${getClientHost()}${process.env.API_PATH}/quizzes/${quizId}`;

    const headersConfig = {
        'Content-Type': 'application/json'
    };

    const userId = getOrCreateEncodedUUID();

    if (isGated && authHeader) {
        headersConfig.Authorization = authHeader;
    }

    if (!isGated && userId) {
        headersConfig['X-User-Id'] = userId;
    }

    return new Promise((resolve, reject) => {
        fetch(endpoint, { headers: headersConfig })
            .then((response) => {
                if (!response.ok) {
                    console.warn('Playbook quiz embed unsuccessful', quizId);
                    return null;
                }

                return response.json();
            })
            .then((quizData) => {
                if (!quizData) {
                    return;
                }

                sortQuizOptions(quizData);

                resolve(quizData);
            })
            .catch((error) => {
                console.warn('Playbook quiz embed unsuccessful', error);
                reject(error);
            });
    });
};

/**
 * POST /quizzes/{quiz_id}/answers
 * Submit an answer to a quiz question
 *
 * @param {string} quizId - quiz ID
 * @param {string} payload - answer in payload
 * @param {boolean} isGated - is the quiz SSO gated
 * @param {string } authHeader - auth header for SSO authentication
 * @returns {Promise} -
 */
export const sendAnswer = (quizId, payload, isGated, authHeader) => {
    const QUIZ_HOST = `${getClientHost()}${process.env.API_PATH}`;

    const endpoint = isGated
        ? `https://${QUIZ_HOST}/gated/quizzes/${quizId}/answers`
        : `https://${QUIZ_HOST}/quizzes/${quizId}/answers`;

    const userId = getOrCreateEncodedUUID();

    const headersConfig = {
        'Content-Type': 'application/json'
    };

    if (isGated && authHeader) {
        headersConfig.Authorization = authHeader;
    }

    if (!isGated && userId) {
        headersConfig['X-User-Id'] = userId;
    }

    const newPayload = {
        answers: [payload]
    };

    return new Promise((resolve, reject) => {
        fetch(endpoint, {
            method: 'POST',
            headers: headersConfig,
            body: JSON.stringify(newPayload)
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if (
                    response.status &&
                    !response.status.toString().match(/2\d{2}/g)
                ) {
                    // Non sucessful response
                    reject(response);
                    return;
                }
                resolve(response);
            })
            .catch((error) => {
                console.error(
                    'Playbook - Quiz question submission answer unsuccessful.',
                    error
                );
                reject(error);
            });
    });
};

/**
 * POST /quizzes/{quiz_id}/view
 * Submit a view to the quiz
 *
 * @param {string} quizId - quiz ID
 * @param {string} isGated - controls which endpoint to hit, sso gated or normal
 * @param {string} authHeader - gated auth token
 * @returns {Promise} -
 */
export const sendView = (quizId, isGated, authHeader) => {
    const QUIZ_HOST = `${getClientHost()}${process.env.API_PATH}`;

    const endpoint = isGated
        ? `https://${QUIZ_HOST}/gated/quizzes/${quizId}/view`
        : `https://${QUIZ_HOST}/quizzes/${quizId}/view`;

    const userId = getOrCreateEncodedUUID();
    const headersConfig = {
        'Content-Type': 'application/json'
    };

    if (isGated && authHeader) {
        headersConfig.Authorization = authHeader;
    }

    if (!isGated && userId) {
        headersConfig['X-User-Id'] = userId;
    }

    return new Promise((resolve, reject) => {
        fetch(endpoint, {
            method: 'POST',
            headers: headersConfig
        })
            .then((response) => {
                if (
                    response.status &&
                    !response.status.toString().match(/2\d{2}/g)
                ) {
                    // Non sucessful response
                    reject(response);
                    return;
                }
                resolve(response);
            })
            .catch((error) => {
                console.error('Playbook - Quiz view unsuccessful.', error);
                reject(error);
            });
    });
};
