import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Confetti from 'react-confetti';

import { isOverFiftyPercentCorrect } from '../../../helpers/utils';
import { getTotalVotes } from '../../../helpers/schedule';
import TrophyIcon from '../../../icons/TrophyIcon';
import styleSheet from './styles/results.module.scss';
import TotalVotes from '../TotalVotes/TotalVotes.component';

import AnswersAccordion from './AnswersAccordion.component';

const ONE_THOUSAND = 1000;

const styles = styleSheet.locals || {};

/**
 * Component for seeing the results of the quiz
 *
 * @param {object} props quiz option props
 * @param {object} props.quiz quiz option data
 * @param {boolean} props.confettiPlaying should we show confetti
 * @param {func} props.setConfettiPlaying setting function for confetti
 * @param {object} props.submissionSummary subissions object
 * @returns {Function} <Results />
 */
function Results({
    quiz,
    confettiPlaying,
    setConfettiPlaying,
    submissionSummary
}) {
    /* eslint-disable no-magic-numbers */
    const { t } = useTranslation();

    const totalVotes = getTotalVotes(
        quiz?.questions?.[quiz.questions.length - 1]
    );

    const showTotalVotes = totalVotes > ONE_THOUSAND;

    const totalCorrect = submissionSummary?.correct_questions;
    const totalQuestions = submissionSummary?.total_questions;
    const topPercent = submissionSummary?.top_percent;

    const answersCorrect = `${totalCorrect} / ${totalQuestions}`;

    const showCorrectAnswerText = isOverFiftyPercentCorrect(
        totalCorrect,
        totalQuestions
    );

    const topPercentText = t('quiz.topPercent', { percent: topPercent });

    setTimeout(() => setConfettiPlaying(false), 6500);

    return (
        <>
            <style>{styleSheet.toString()}</style>

            <div className={styles.results}>
                <Confetti
                    numberOfPieces={confettiPlaying ? 500 : 0}
                    tweenDuration={100}
                    initialVelocityY={20}
                    colors={['#FD06FF', '#7701FF', '#FE7F82', '#380097']}
                />

                <div className={styles.resultsScore} data-testid="your-score">
                    <div className={styles.resultsScoreLabel}>
                        {t('quiz.yourScore')}
                    </div>

                    <div>
                        <span className={styles.resultsTrophyIcon}>
                            <TrophyIcon />
                        </span>
                        <span className={styles.resultsAnswersCorrect}>
                            {answersCorrect}
                        </span>
                    </div>
                </div>

                <div
                    className={styles.resultsAnswerText}
                    data-testid="answer-text"
                >
                    {showCorrectAnswerText
                        ? quiz?.well_done_text
                        : quiz?.consolation_text}
                </div>

                <div
                    className={styles.resultsTopPercentText}
                    data-testid="top-percent-text"
                >
                    {topPercentText}
                </div>

                <AnswersAccordion
                    quiz={quiz}
                    submissionSummary={submissionSummary}
                />

                {showTotalVotes && (
                    <TotalVotes
                        votes={totalVotes}
                        margin={true}
                        isResultsView={true}
                    />
                )}
            </div>
        </>
    );
}

Results.propTypes = {
    quiz: PropTypes.object.isRequired,
    confettiPlaying: PropTypes.bool.isRequired,
    setConfettiPlaying: PropTypes.func.isRequired,
    submissionSummary: PropTypes.object
};

export default Results;
