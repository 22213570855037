import PropTypes from 'prop-types';
import classNames from 'classnames';

import styleSheet from './totalVotes.module.scss';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

/**
 * Component for showing the number of participants in a question
 *
 * @param {object} props props
 * @param {object} props.votes number of total votes
 * @param {object} props.margin if a styling margin should be added
 * @param {object} props.isTrivia is it a trivia
 * @param {object} props.isResultsView is it results view
 * @returns {Function} <TotalVotes />
 */
const TotalVotes = function ({
    votes,
    margin = false,
    isResultsView = false,
    ...props
}) {
    const { t } = useTranslation();
    const cls = classNames(styles.totalVotes, {
        [styles.totalVotesMargin]: margin,
        [styles.totalVotesShow]: isResultsView
    });
    return (
        <div
            className={cls}
            style={props.style || {}}
            data-testid={'total-participants'}
        >
            <style>{styleSheet.toString()}</style>
            <div className={styles.totalVotesCounter} {...props}>
                {t('quiz.totalParticipants')}
                {': '}
                {votes}
            </div>
        </div>
    );
};

TotalVotes.propTypes = {
    votes: PropTypes.number.isRequired,
    margin: PropTypes.bool,
    style: PropTypes.object,
    isTrivia: PropTypes.bool,
    isResultsView: PropTypes.bool
};

export default TotalVotes;
